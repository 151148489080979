import { Location } from "./questionnaireState";
import { UserInfo } from "./authState";

export type Order = {
  id: number;
  product: string;
  prod_category: number | null;
  variant: string;
  prod_brand_id: number;
  prod_id: string;
  var_id: string;
  prod_image: string;
  market_retail_price: string;
};

export type Orders = {
  order_count: number,
  order_final_price: number
}

export type UserOrder = {
  id: number;
  product: string;
  status: string;
  timestamp: string;
};

export type PickupOrder = {
  id: number;
  user: string;
  email: string;
  phone_number: string;
  product: string;
  prod_category: string;
  prod_id: number;
  prod_image: string;
  variant: string;
  final_price: number;
  status: string;
  timestamp: Date;
  updated: Date;
  city: string;
};

export type Warranty = {
  id: number,
  warranty: string,
  image: string,
  weightage: number
}

export type UpdatePickupOrder = {
  id: number;
  user: string;
  email: string;
  phone_number: string;
  assigned_to: string;
  assigned_executive_number: string;
  product: string;
  prod_image: string;
  variant: string;
  market_retail_price: number;
  auto_evaluated_price: number;
  final_price: number;
  used_refferal_code: string;
  pick_up_location: string;
  state: string;
  city: string;
  status: string;
  schedualed_date: string;
  time_slot: string;
  preffered_payment_option: string;
  timestamp: Date;
  updated: Date;
  warranty: string;
};

export type DetailsOrder = {
  id: number;
  user: string;
  email: string;
  phone_number: string;
  assigned_to: string;
  assigned_executive_number: string;
  product: string;
  prod_id: string;
  prod_image: string;
  variant: string;
  var_id: string;
  market_retail_price: number;
  auto_evaluated_price: number;
  final_price: number;
  used_refferal_code: string;
  pick_up_location: string;
  state: string;
  city: string;
  status: string;
  schedualed_date: string;
  time_slot: string;
  preffered_payment_option: string;
  timestamp: string;
  updated: string;
  warranty: string;
};

export type DeviceReport = {
  id: number;
  report: [];
  accessories: [];
  warranty: Warranty[];
  attachedLocation: Location[]
  laptop_config_report: []
};

export type OrderInquiry = {
  id: number;
  product: string;
  status: string;
  timestamp: string;
  user: string;
  phone_number: string;
  final_price: number;
  refferal_code_for_telecaller: number;
};

export type OrderInquiryState = {
  results: OrderInquiry[] | undefined;
  success?: boolean;
  error: string | null;
  loading: boolean;
  count?: number;
  next?: string | null;
  previous?: string | null;
};

export type OrderState = {
  order_details: Order | undefined;
  success?: boolean;
  loading: boolean;
  error: string | null;
};

export type OrdersState = {
  orders: Orders | undefined,
  success?: boolean,
  loading: boolean,
  error: string | null
}

export const OrderInitialState: OrderState = {
  order_details: undefined,
  loading: false,
  error: null,
};

export type PickupOrderState = {
  pickup_order: PickupOrder | undefined;
  success?: boolean;
  loading: boolean;
  error: string | null;
  isOrderIncomple: boolean;
};

export type UserOrderState = {
  user_orders: UserOrder[] | [];
  success?: boolean;
  loading: boolean;
  error: string | null;
};

export type OrderDetailsState = {
  order_details: DetailsOrder | undefined;
  success?: boolean;
  loading: boolean;
  error: string | null;
};

export type UpdatePickupOrderState = {
  update_pickup_order: UpdatePickupOrder | undefined;
  user_info: UserInfo | undefined,
  success?: boolean;
  loading: boolean;
  error: string | null;
};

export type DeviceReportState = {
  device_report: DeviceReport | undefined;
  success?: boolean;
  loading: boolean;
  error: string | null;
};

export type CancelOrderState = {
  details: string | null;
  success?: boolean;
  loading: boolean;
  error: string | null;
}

export const CancelOrderInitialState: CancelOrderState = {
  details: null,
  loading: false,
  error: null,
}

export const PickupOrderInitialState: PickupOrderState = {
  pickup_order: undefined,
  loading: false,
  error: null,
  isOrderIncomple: false
};

export const OrdersInitialState: OrdersState = {
  orders: undefined,
  loading: false,
  error: null,
}

export const UserOrderInitialState: UserOrderState = {
  user_orders: [],
  loading: false,
  error: null,
};

export const OrderDetailsInitialState: OrderDetailsState = {
  order_details: undefined,
  loading: false,
  error: null,
};

export const DeviceReportInitialState: DeviceReportState = {
  device_report: undefined,
  loading: false,
  error: null,
};

export const UpdatePickupOrderInitilState: UpdatePickupOrderState = {
  update_pickup_order: undefined,
  user_info: undefined,
  loading: false,
  error: null,
};

export const OrderInquiryInitialState: OrderInquiryState = {
    results: undefined,
    loading: false,
    error: null,
}
