import Skeleton from "react-loading-skeleton";

const InitialScreenLoader = () => (
  <div className="">
    {/* Your component structure */}
    <Skeleton height={"10vh"} width={"100%"} />
    <div style={{ padding: "5px" }}>
      <Skeleton height={"95vh"} width={"100%"} />
    </div>
    {/* <Skeleton style={{marginLeft: '20px', marginRight: "20px"}} height={'40vh'} width={'100%'}/> */}
  </div>
);

export default InitialScreenLoader;
