import "./appdownload.scss";
export default function AppDownload() {
  return (
    <>
    <div className="app-download-container">
    <div className="container download-app-section">
        <div className="button-container">
          <a
            href="https://play.google.com/store/apps/details?id=app.buybackart.com"
            target="_blank"
          >
            <img
              src="https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/google-play-logo-mob.svg"
              alt="playstore icon"
            ></img>
          </a>
          <a
            href="https://apps.apple.com/in/app/buybackart-sell-old-phone/id6445955804"
            target="_blank"
          >
            <img
              src="https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/apple-store-logo-mob.svg"
              alt="appstore icon"
            ></img>
          </a>
        </div>
      </div>
    </div>
     
    </>
  );
}
