import { useEffect, Fragment, memo } from "react";
import { fetchCityList } from "../actions/fixtureActions";
import { useAppDispatch } from "../app/hooks";

type Props = {
  page: number;
};

const ListCityCall = (props: Props) => {
  const { page } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCityList(page));
  }, [dispatch, page]);

  return <Fragment></Fragment>;
};

export default memo(ListCityCall);
