import React, { useEffect } from "react";
import { Spinner, Input } from "reactstrap";
import { Controller } from "react-hook-form";
import { useAppDispatch } from "../../app/hooks";
import { MouseEvent, ChangeEvent } from "react";
import { createNewUserOtpReset } from "../../features/accountSlice";
import { Control, FieldErrors, UseFormHandleSubmit } from "react-hook-form";
import "./loginmodal.scss";

interface SignUpProps {
    switchToAuth: (choice: "login" | "signup" | "loginWithOtp") => void;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void,
    loginForm: {
        phone_number: string,
        password: string,
        otp: string,
    };
    loginOtpFormHandler: (e: MouseEvent<HTMLButtonElement>) => void;
    userRegistrationHandleSubmit: (data: any) => void;
    handleSubmit: UseFormHandleSubmit<any>;
    control: Control<any>;
    errors: FieldErrors<any>;
    signUpProps: {
        isCreateUserSuccess: boolean | undefined,

        isCreateUserError: string | null,
        isCreateUserLoading: boolean
    }
}

const NewSignUpModal: React.FC<SignUpProps> = ({
    switchToAuth,
    loginForm,
    handleInputChange,
    signUpProps,
    errors,
    handleSubmit,
    userRegistrationHandleSubmit,
    loginOtpFormHandler,
    control
}) => {
    const { isCreateUserSuccess, isCreateUserError, isCreateUserLoading } = signUpProps
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isCreateUserSuccess) {
            dispatch(createNewUserOtpReset())
        }
    }, [isCreateUserSuccess, dispatch])



    return (
        <>
            <div className="overlay-container none-mob">
                <div className="overlay">
                    <div className="overlay-panel overlay-right">
                        <img
                            className="img-log"
                            src="https://buybackart-s3-prod-bucket.s3.ap-south-1.amazonaws.com/static/userLogin/sigup.png"
                            alt="None"
                        />

                        <button
                            className="ghost bttn-log"
                            id="signIn"
                            onClick={() => switchToAuth("login")}
                        >
                            Sign In
                        </button>
                        <p> or</p>
                        <button
                            className="ghost bttn-log mt-1"
                            onClick={() => switchToAuth("loginWithOtp")}
                        >
                            Login with OTP
                        </button>
                    </div>
                </div>
            </div>
            <div className="form-container sign-in-container">
                <form className="faram">
                    <h3 className="heading-log">Create Account</h3>
                    <div className="social-container">
                        <a href="#" className="social ahref">
                            <i className="fab fa-facebook-f" />
                        </a>
                        <a href="#" className="social ahref">
                            <i className="fab fa-google-plus-g" />
                        </a>
                    </div>
                    {/* <span>or use your email for registration</span> */}
                    {(
                        <>
                            <Controller
                                name="phone_number"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        className="react-select inp"
                                        invalid={errors.phone_number && true}
                                        type="text"
                                        id="phone_number"
                                        placeholder="Phone Number"
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        className="react-select inp"
                                        invalid={errors.name && true}
                                        type="text"
                                        id="name"
                                        placeholder="Name"
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        className="react-select inp"
                                        invalid={errors.email && true}
                                        type="email"
                                        id="email"
                                        placeholder="Email"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        className="react-select inp"
                                        invalid={errors.password && true}
                                        type="password"
                                        id="password"
                                        placeholder="Enter Password"
                                        {...field}
                                    />
                                )}
                            />
                            {isCreateUserError && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                    {isCreateUserError}
                                </div>
                            )}
                            <button
                                className={`bttn mt-2 ${isCreateUserLoading ? "dimmed-button" : ""
                                    }`}
                                onClick={handleSubmit(userRegistrationHandleSubmit)}
                            >
                                {isCreateUserLoading && (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        size="sm"
                                        className=""
                                    />
                                )}
                                <span className="mx-3">SignUp</span>
                            </button>
                            <h2>
                                <span className="none-bs">--OR--</span>
                            </h2>
                            <button
                                onClick={() => switchToAuth("loginWithOtp")}
                                className="bttn none-bs"
                            >
                                Login With OTP
                            </button>
                        </>
                    )}
                </form>
            </div>
        </>
    );
};

export default NewSignUpModal;
