export type Banner = {
    id: number;
    image: string;
    mobile_image: string;
    web_mobile_image: string;
    alt_txt: string;
};

export type BannerListState = {
    banners: Banner[],
    success?: boolean;
    error: string | null;
    loading: boolean;
}

export const BannerListInitialState: BannerListState = {
    banners: [],
    error: null,
    loading: false,
}