import Cookies from "js-cookie";
import { UserInfo } from "../state/authState";

export const saveAuthData = (user_info: UserInfo) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7); // Expires in 7 days

  // document.cookie = `authData=${JSON.stringify(user_info)}; expires=${expires}; path=/; SameSite=Strict; Secure`;
  // Store authentication data in cookies with expiration time
  Cookies.set("authUser", JSON.stringify(user_info), { expires: expirationDate, path: '/', sameSite: 'strict', secure: true });
};

export const getAuthData = () => {
  // Retrieve authentication data from cookies
  // const cookieString = document.cookie;
  // const cookies = cookieString.split(';').map(cookie => cookie.trim());
  // const authCookie = cookies.find(cookie => cookie.startsWith('authData='));
  // return authCookie ? JSON.parse(authCookie.split('=')[1]) : null;
  const authDataCookie = Cookies.get("authUser");
  return authDataCookie ? JSON.parse(authDataCookie) : null;
};

export const isAuthenticated = () => {
  const authData = getAuthData();
  // Check if authentication data exists and is valid (e.g., token not expired)
  return authData && authData.token ? true : false;
};

export const isBannerShowOrNot = () => {
  // Store authentication data in cookies
  document.cookie = `isBannerNotShow=${String(true)}; path=/; SameSite=Strict; Secure`;
};

export const getBannerStatusFromCookie = () => {
  const cookies = document.cookie.split(';').map(cookie => cookie.trim());
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === 'isBannerNotShow') {
      return value === 'true';
    }
  }
  return false; // Default value if cookie is not found
};