import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BlogListState, BlogListInitialState, BlogDetailState, BlogDetailInitialState } from "../state/blogState";


const blogSlice = createSlice({
    name: "blog",
    initialState: BlogListInitialState,
    reducers: {
        blogRequest: (state) => {
            state.loading = true;
            state.blogs = undefined;
        },
        blogSuccess: (
            state,
            action: PayloadAction<BlogListState>
        ) => {
            state.loading = false;
            state.success = true;
            state.blogs = action.payload.blogs;
        },
        blogFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});

const blogDetailSlice = createSlice({
    name: "blogDetail",
    initialState: BlogDetailInitialState,
    reducers: {
        blogDetailRequest: (state) => {
            state.loading = true;
            state.blog = undefined;
        },
        blogDetailSuccess: (
            state,
            action: PayloadAction<BlogDetailState>
        ) => {
            state.loading = false;
            state.success = true;
            state.blog = action.payload.blog;
        },
        blogDetailFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});

export const {blogDetailFailure, blogDetailRequest, blogDetailSuccess} = blogDetailSlice.actions
export const BlogDetailReducer = blogDetailSlice.reducer;

export const { blogRequest, blogSuccess, blogFailure } =
    blogSlice.actions;
export const BlogReducer = blogSlice.reducer;