export type Category = {
  id: number;
  slug: string;
  title: string;
  image: string;
};

export type CategoryListState = {
  categories: Category[];
  success?: boolean;
  page: number | null;
  pages: number | null;
  error: string | null;
  loading: boolean;
};

export const initialState: CategoryListState = {
  categories: [],
  page: null,
  pages: null,
  error: null,
  loading: false,
};
