export type Warranty = {
    id: number,
    warranty: string,
    image: string,
    weightage: number
}

export type ListWarrantyState = {
    warranty: Warranty[] | undefined
    loading: boolean,
    error: string | null,
    success?: boolean,

}

export const ListWarrantyInitialState: ListWarrantyState = {
    warranty: undefined,
    loading: false,
    error: null,
}