import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExcitingOfferListInitialState, ExcitingOfferListState } from "../state/excitingOfferState";

const excitingOffersListSlice = createSlice({
    name: "excitingOffersList",
    initialState: ExcitingOfferListInitialState,
    reducers: {
      excitingOfferListRequest: (state) => {
        state.loading = true;
        state.results = [];
      },
      excitingOfferListSuccess: (
        state,
        action: PayloadAction<ExcitingOfferListState>
      ) => {
        state.loading = false;
        state.success = true;
        state.results = action.payload.results;
      },
      excitingOfferListFailure: (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      },
    },
  });

  export const {
    excitingOfferListFailure,
    excitingOfferListRequest,
    excitingOfferListSuccess,
  } = excitingOffersListSlice.actions;
  
  export const excitingOffersReducer = excitingOffersListSlice.reducer;