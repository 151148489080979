export type Testimonial = {
    id: number;
    image: string;
    name: string;
    designation: string;
    ratings: number;
    feedback: string;
};

export type TestimonialListState = {
    testimonials: Testimonial[] | undefined;
    success?: boolean;
    error: string | null;
    loading: boolean;
};

export const TestimonialListInitialState: TestimonialListState = {
    testimonials: undefined,
    error: null,
    loading: false,
};