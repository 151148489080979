import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  brandInitialState,
  brandListState,
  brandSeriesInitialState,
  brandSeriesListState,
  brandProductSeriesListInitialState,
  brandProductSeriesListState,
  brandProductListState,
  brandProductListInitialState,
  MostSellingBrandState,
  MostSellingBrandInitialState,
  MostSellingBrandProductListInitialState,
  MostSellingBrandProductListState,
} from "../state/brandState";

const categoryBrandListSlice = createSlice({
  name: "categoryBrandLit",
  initialState: brandInitialState,
  reducers: {
    brandListRequest: (state) => {
      state.loading = true;
      state.brands = [];
    },
    brandListSuccess: (state, action: PayloadAction<brandListState>) => {
      state.loading = false;
      state.success = true;
      state.id = action.payload.id;
      state.title = action.payload.title;
      state.brands = action.payload.brands;
      state.page = action.payload.page;
      state.pages = action.payload.pages;
    },
    brandListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const brandSeriesListSlice = createSlice({
  name: "brandSeriesList",
  initialState: brandSeriesInitialState,
  reducers: {
    seriesListRequest: (state) => {
      state.loading = true;
      state.series_brand = [];
    },
    seriesListSuccess: (state, action: PayloadAction<brandSeriesListState>) => {
      state.loading = false;
      state.success = true;
      state.id = action.payload.id;
      state.series_brand = action.payload.series_brand;
    },
    seriesListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

const brandProductSeriesSlice = createSlice({
  name: "productSeriesList",
  initialState: brandProductSeriesListInitialState,
  reducers: {
    productSeriesListRequest: (state) => {
      state.loading = true;
      state.product_series = [];
    },
    productSeriesListSuccess: (
      state,
      action: PayloadAction<brandProductSeriesListState>
    ) => {
      state.loading = false;
      state.success = true;
      state.id = action.payload.id;
      state.product_series = action.payload.product_series;
      state.page = action.payload.page;
      state.pages = action.payload.pages;
    },
    productSeriesListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    productSeriesListReset: (state) => {
      state.loading = false;
      state.success = false;
      state.product_series = [];
    },
  },
});

const brandProductListSlice = createSlice({
  name: "brandProductList",
  initialState: brandProductListInitialState,
  reducers: {
    productListRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    productListSuccess: (
      state,
      action: PayloadAction<brandProductListState>
    ) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
    },
    productListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    productListReset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.results = [];
    },
  },
});

const sellingBrandListSlice = createSlice({
  name: "brandProductList",
  initialState: MostSellingBrandInitialState,
  reducers: {
    sellingBrandListRequest: (state) => {
      state.loading = true;
      state.brand_list = undefined;
    },
    sellingBrandListSuccess: (
      state,
      action: PayloadAction<MostSellingBrandState>
    ) => {
      state.loading = false;
      state.success = true;
      state.brand_list = action.payload.brand_list;
    },
    sellingBrandListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

const sellingBrandProductListWithCategorySlice = createSlice({
  name: "brandProductListWithCategory",
  initialState: MostSellingBrandProductListInitialState,
  reducers: {
    sellingBrandListWithCategoryRequest: (state) => {
      state.loading = true;
      state.most_selling_products = undefined;
    },
    sellingBrandListWithCategorySuccess: (
      state,
      action: PayloadAction<MostSellingBrandProductListState>
    ) => {
      state.loading = false;
      state.success = true;
      state.most_selling_products = action.payload.most_selling_products;
    },
    sellingBrandListWithCategoryFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { brandListRequest, brandListSuccess, brandListFailure } =
  categoryBrandListSlice.actions;
export const categoryBrandListReducer = categoryBrandListSlice.reducer;

export const { seriesListRequest, seriesListSuccess, seriesListFailure } =
  brandSeriesListSlice.actions;
export const brandSeriesListReducer = brandSeriesListSlice.reducer;

export const {
  productSeriesListRequest,
  productSeriesListSuccess,
  productSeriesListFailure,
  productSeriesListReset,
} = brandProductSeriesSlice.actions;
export const productSeriesListReducer = brandProductSeriesSlice.reducer;

export const {
  productListRequest,
  productListSuccess,
  productListFailure,
  productListReset,
} = brandProductListSlice.actions;

export const productListReducer = brandProductListSlice.reducer;

export const {
  sellingBrandListRequest,
  sellingBrandListFailure,
  sellingBrandListSuccess,
} = sellingBrandListSlice.actions;
export const SellingBrandListReducer = sellingBrandListSlice.reducer;

export const {
  sellingBrandListWithCategoryRequest,
  sellingBrandListWithCategoryFailure,
  sellingBrandListWithCategorySuccess
} = sellingBrandProductListWithCategorySlice.actions
export const SellingBrandProductListReducer = sellingBrandProductListWithCategorySlice.reducer
