import { Fragment, lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import InitialScreenLoader from "./components/CustomPageLoader/HomeScreenLoader/InitialScreenLoader";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles/global.scss";
import ThankYou from "./screens/ThankYouScreen/ThankYou";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
const HomeScreen = lazy(() => import("./screens/HomeScreen/HomeScreen"));
const About = lazy(() => import("./screens/About/About"));
const ContactUs = lazy(() => import("./screens/Contact/ContactUs"));
const BePartner = lazy(() => import("./screens/Partner/BePartner"));
const OurStory = lazy(() => import("./screens/Story/OurStory"));
const BlogList = lazy(() => import("./screens/Blog/BlogList"));
const BlogDetail = lazy(() => import("./screens/Blog/BlogDetail"));
const CategoryToBrand = lazy(
  () => import("./screens/CategoryToBrand/CategoryToBrand")
);
const BrandToSeries = lazy(
  () => import("./screens/BrandToSeries/BrandToSeries")
);
const ProductToProductDetails = lazy(
  () => import("./screens/ProductToProductDetail/ProductToProductDetails")
);
const LocationSelect = lazy(
  () => import("./screens/LocationSelect/LocationSelect")
);
const OrderManager = lazy(() => import("./screens/OrderManager/OrderManager"));
const TermsAndConditions = lazy(
  () => import("./screens/TermsAndCondition/TermsAndConditions")
);
const PrivacyPolicy = lazy(
  () => import("./screens/PrivacyPolicy/PrivacyPolicy")
);
const RefundAndCancellation = lazy(
  () => import("./screens/RefundAndCancellation/RefundAndCancellation")
);
const ProductInspection = lazy(
  () => import("./screens/ProductInspection/ProductInspection")
);
const AccessoriesAndWarrenty = lazy(
  () => import("./screens/AccessoriesAndWarrenty/AccessoriesAndWarranty")
);
const SellNow = lazy(() => import("./screens/SellNow/SellNow"));
const AddressDetail = lazy(
  () => import("./screens/AddressDetail/AddressDetail")
);
const LaptopExtras = lazy(() => import("./screens/LaptopExtras/LaptopExtras"));
const UserProfile = lazy(() => import("./screens/UserProfile/UserProfile"));
const Inquiry = lazy(() => import("./screens/Inquiry/Inquiry"));
const OrderUpdate = lazy(() => import("./screens/OrderUpdate/OrderUpdate"));
const OrderDetail = lazy(() => import("./screens/OederDetail/OrderDetail"));
const Error404 = lazy(() => import("./screens/Error404/Error404"));

function App({ locationUser }: any) {
  useEffect(() => {
  }, [])

  return (
    <Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <HomeScreen locationUser={locationUser} />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <About />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <ContactUs />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/be-partner"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <BePartner />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/our-story"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <OurStory />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/blogs"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <BlogList />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/blogs/:blogSlug"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <BlogDetail />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/:categorySlug"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <CategoryToBrand />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/:categorySlug/:brandSlug"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <BrandToSeries />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/:categorySlug/:brandSlug/:productSlug"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <ProductToProductDetails />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/location-select"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <LocationSelect />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/sell-now"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <OrderManager />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/terms-and-condition"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <TermsAndConditions />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <PrivacyPolicy />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/refund-and-cancellation"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <RefundAndCancellation />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/product-inspection"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <ProductInspection />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/accessories-and-warranty"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <AccessoriesAndWarrenty />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/confirm"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <SellNow locationUser={locationUser} />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/address-detail"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <AddressDetail />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/laptop-extras"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <LaptopExtras />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/thank-you"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <ThankYou />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/profile"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <UserProfile />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/inquiry"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <Inquiry />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/order-update/:id"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <OrderUpdate locationUser={locationUser} />
              </ErrorBoundary>
            </Suspense>
          }
        />
        <Route
          path="/order-detail/:id"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <OrderDetail locationUser={locationUser} />
              </ErrorBoundary>

            </Suspense>
          }
        />
        <Route
          path="/404"
          element={
            <Suspense fallback={<InitialScreenLoader />}>
              <ErrorBoundary>
                <Error404 />
              </ErrorBoundary>

            </Suspense>
          }
        />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </Fragment>
  );
}

export default App;
