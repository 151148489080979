export type SubSectionCat = {
  id: number;
  question_of_subquestion: string;
  image: string;
  weightage: number;
};

export type SpecialSubQues = {
  id: number;
  sub_question: string;
  image: string;
  weightage: number;
  subsection_category: SubSectionCat[];
};

export type Questions = {
  id: number;
  prop_image: string;
  question_cat: string;
  question_prod: string;
  question_brand: string;
  question: string;
  if_yes: string;
  if_no: string;
  weightage: number;
  specialsubquestion: SpecialSubQues[];
};

export type Location = {
  id: number;
  name: string;
  weightage: number;
  image: string;
  add: boolean;
};

export type QuestionnaireState = {
  questionnaire: Questions | undefined;
  success?: boolean;
  error: string | null;
  loading: boolean;
};

export type LocationState = {
    results: Location[] | undefined,
    success?: boolean;
    error: string | null;
    loading: boolean;
}

export const LocationInitialState: LocationState = {
    results: undefined,
    loading: true,
    error: null
}

export const QuestionnaireInitialState: QuestionnaireState = {
  questionnaire: undefined,
  error: null,
  loading: false,
};

interface SubsectionCategory {
  id: number;
  image: string;
  question_of_subquestion: string;
  subsection_category: number;
  weightage: number;
}
interface QuestionProps {
  id: number;
  image: string;
  sub_question: string;
  subsection_category: SubsectionCategory[]; // Replace with the correct data type
  weightage: number;
}

export const initialQuestion: QuestionProps = {
  id: 0,
  image: "",
  sub_question: "",
  subsection_category: [],
  weightage: 0,
};
