import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  QuestionnaireState,
  QuestionnaireInitialState,
  LocationInitialState,
  LocationState,
} from "../state/questionnaireState";

const questionnaireListSlice = createSlice({
  name: "questionnaireList",
  initialState: QuestionnaireInitialState,
  reducers: {
    questionListRequest: (state) => {
      state.loading = true;
      state.questionnaire = undefined;
    },
    questionListSuccess: (state, action: PayloadAction<QuestionnaireState>) => {
      state.loading = false;
      state.success = true;
      state.questionnaire = action.payload.questionnaire;
    },
    questionListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    questionListReset: (state) => {
      state.questionnaire = undefined;
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
});

const locationForWeightageSlice = createSlice({
  name: "locationForWeightageSlice",
  initialState: LocationInitialState,
  reducers: {
    locationListReuest: (state) => {
      state.loading = true;
      state.results = undefined;
    },
    locationListSuccess: (state, action: PayloadAction<LocationState>) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    locationListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const {
  questionListRequest,
  questionListSuccess,
  questionListFailure,
  questionListReset,
} = questionnaireListSlice.actions;

export const QuestionnaireListReducer = questionnaireListSlice.reducer;

export const { locationListFailure, locationListReuest, locationListSuccess } =
  locationForWeightageSlice.actions;
export const LocationWeighageReducer = locationForWeightageSlice.reducer;
