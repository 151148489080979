import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Banner, BannerListInitialState, BannerListState } from "../state/bannerState";


const bannerListSlice = createSlice({
    name: "bannerList",
    initialState: BannerListInitialState,
    reducers: {
        bannerListRequest: (state) => {
            state.loading = true;
            state.banners = [];
        },
        bannerListSuccess: (state, action: PayloadAction<BannerListState>) => {
            state.loading = false;
            state.success = true;
            state.banners = action.payload.banners;
        },
        bannerListFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});


export const {
    bannerListRequest,
    bannerListSuccess,
    bannerListFailure,
} = bannerListSlice.actions;
export const BannerListReducer = bannerListSlice.reducer;