import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  StateWithCityInitialState,
  StateWithCityState,
  CityListState,
  CityListInitialState,
} from "../state/fixtureState";

const stateWithCitiesSlice = createSlice({
  name: "stateWithCities",
  initialState: StateWithCityInitialState,
  reducers: {
    stateCitiesRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    stateCitiesSuccess: (state, action: PayloadAction<StateWithCityState>) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    stateCitiesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

const cityListSlice = createSlice({
  name: "cityList",
  initialState: CityListInitialState,
  reducers: {
    cityListRequest: (state) => {
      state.loading = true;
      state.results = undefined;
    },
    cityListSuccess: (state, action: PayloadAction<CityListState>) => {
      state.loading = false;
      state.success = true;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
      state.results = action.payload.results;
    },
    cityListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    cityListReset: (state) => {
      state.loading = false;
      state.results = undefined;
      state.success = false;
      state.error = null;
    },
  },
});

export const { stateCitiesRequest, stateCitiesSuccess, stateCitiesFailure } =
  stateWithCitiesSlice.actions;
export const StateWithCitiesReducer = stateWithCitiesSlice.reducer;

export const {
  cityListRequest,
  cityListSuccess,
  cityListFailure,
  cityListReset,
} = cityListSlice.actions;
export const cityListReducer = cityListSlice.reducer;
