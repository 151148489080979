export type Blog = {
    id: number;
    image: string;
    title: string;
    slug: string;
    author: string | null;
    description: string;
};

export type BlogListState = {
    blogs: Blog[] | undefined;
    success?: boolean;
    error: string | null;
    loading: boolean;
};

export const BlogListInitialState: BlogListState = {
    blogs: undefined,
    error: null,
    loading: false,
};


export type BlogDetailState = {
    blog: Blog | undefined;
    success?: boolean;
    error: string | null;
    loading: boolean;
};

export const BlogDetailInitialState: BlogDetailState = {
    blog: undefined,
    error: null,
    loading: false,
};