import {
  ProductDetailState,
  ProductDetailInitialState,
  ExcitingOfferInitialState,
  ExcitingOfferState,
  SearchProductInitialState,
  SearchProductState,
  ProductVariantState,
  ProductVariantInitialState,
} from "../state/productState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const productDetailSlice = createSlice({
  name: "productDetail",
  initialState: ProductDetailInitialState,
  reducers: {
    productDetailRequest: (state) => {
      state.loading = true;
      state.results = undefined;
    },
    productDetailSuccess: (
      state,
      action: PayloadAction<ProductDetailState>
    ) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    productDetailFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

const searchProductSlice = createSlice({
  name: "searchProduct",
  initialState: SearchProductInitialState,
  reducers: {
    searchProductRequest: (state) => {
      state.loading = true;
      state.results = undefined;
    },
    searchProductSuccess: (
      state,
      action: PayloadAction<SearchProductState>
    ) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    },
    searchProductFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    searchProductReset: (state) => {
      state.results = undefined;
      state.error = null;
      state.loading = false;
      state.success = false;
    },
  },
});

const excitingOffersListSlice = createSlice({
  name: "excitingOffersList",
  initialState: ExcitingOfferInitialState,
  reducers: {
    excitingOfferRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    excitingOfferSuccess: (
      state,
      action: PayloadAction<ExcitingOfferState>
    ) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    excitingOfferFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

const productVariantSlice = createSlice({
  name: "productVariant",
  initialState: ProductVariantInitialState,
  reducers: {
    productVariantRequest: (state) => {
      state.loading = true;
      state.results = undefined;
    },
    productVariantSuccess: (
      state,
      action: PayloadAction<ProductVariantState>
    ) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    productVariantFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const {
  productDetailRequest,
  productDetailSuccess,
  productDetailFailure,
} = productDetailSlice.actions;

export const ProductDetailReducer = productDetailSlice.reducer;

export const {
  excitingOfferFailure,
  excitingOfferRequest,
  excitingOfferSuccess,
} = excitingOffersListSlice.actions;

export const excitingOfferListReducer = excitingOffersListSlice.reducer;

export const {
  searchProductFailure,
  searchProductRequest,
  searchProductSuccess,
  searchProductReset,
} = searchProductSlice.actions;

export const SearchProductReducer = searchProductSlice.reducer;

export const {
  productVariantFailure,
  productVariantRequest,
  productVariantSuccess,
} = productVariantSlice.actions;
export const ProductVariantReducer = productVariantSlice.reducer;
