import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RamInitialState,
  RamState,
  HardiskState,
  HardiskInitialState,
  ModelYearInitialState,
  ModelYearState,
  ScreenSizeInitialState,
  ScreenSizeState,
  ProcessorInitialState,
  ProcessorState,
  GraphicCardInitialState,
  GraphicCardState,
} from "../state/laptopConfigState";

const processorListSlice = createSlice({
  name: "proccessorList",
  initialState: ProcessorInitialState,
  reducers: {
    processorListRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    processorListSuccess: (state, action: PayloadAction<ProcessorState>) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    processorListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    processorListReset: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.results = [];
    },
  },
});

const ramListSlice = createSlice({
  name: "ramList",
  initialState: RamInitialState,
  reducers: {
    ramListRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    ramListSuccess: (state, action: PayloadAction<RamState>) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    ramListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    ramListReset: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.results = [];
    },
  },
});

const screenListSlice = createSlice({
  name: "screenList",
  initialState: ScreenSizeInitialState,
  reducers: {
    screenListRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    screenListSuccess: (state, action: PayloadAction<ScreenSizeState>) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    screenListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    screenListReset: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.results = [];
    },
  },
});

const hardDiskListSlice = createSlice({
  name: "hardDiskList",
  initialState: HardiskInitialState,
  reducers: {
    hardDiskListRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    hardDiskListSuccess: (state, action: PayloadAction<HardiskState>) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    hardDiskListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    hardDiskListReset: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.results = [];
    },
  },
});

const graphicCardListSlice = createSlice({
  name: "graphicCardList",
  initialState: GraphicCardInitialState,
  reducers: {
    graphicCardListRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    graphicCardListSuccess: (
      state,
      action: PayloadAction<GraphicCardState>
    ) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    graphicCardListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    graphicCardListReset: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.results = [];
    },
  },
});

const modelYearListSlice = createSlice({
  name: "modelYearList",
  initialState: ModelYearInitialState,
  reducers: {
    modelYearListRequest: (state) => {
      state.loading = true;
      state.results = [];
    },
    modelYearListSuccess: (state, action: PayloadAction<ModelYearState>) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    modelYearListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    modelYearListReset: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.results = [];
    },
  },
});

export const {
  processorListFailure,
  processorListRequest,
  processorListSuccess,
  processorListReset,
} = processorListSlice.actions;
export const processorListReducer = processorListSlice.reducer;

export const { ramListFailure, ramListRequest, ramListReset, ramListSuccess } =
  ramListSlice.actions;
export const ramListReducer = ramListSlice.reducer;

export const {
  screenListFailure,
  screenListRequest,
  screenListReset,
  screenListSuccess,
} = screenListSlice.actions;
export const screenListReducer = screenListSlice.reducer;

export const {
  hardDiskListFailure,
  hardDiskListRequest,
  hardDiskListReset,
  hardDiskListSuccess,
} = hardDiskListSlice.actions;
export const hardDiskListReducer = hardDiskListSlice.reducer;

export const {
  graphicCardListReset,
  graphicCardListFailure,
  graphicCardListRequest,
  graphicCardListSuccess,
} = graphicCardListSlice.actions;
export const graphicCardListReducer = graphicCardListSlice.reducer;

export const {
  modelYearListFailure,
  modelYearListRequest,
  modelYearListReset,
  modelYearListSuccess,
} = modelYearListSlice.actions;
export const modelYearListReducer = modelYearListSlice.reducer;
