import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OrderState,
  OrderInitialState,
  PickupOrderState,
  PickupOrderInitialState,
  UpdatePickupOrderState,
  UpdatePickupOrderInitilState,
  UserOrderState,
  UserOrderInitialState,
  OrderDetailsState,
  OrderDetailsInitialState,
  DeviceReportInitialState,
  DeviceReportState,
  OrderInquiryInitialState,
  OrderInquiryState,
  CancelOrderInitialState,
  CancelOrderState,
  OrdersInitialState,
  OrdersState,
} from "../state/orderState";

const orderInitSlice = createSlice({
  name: "order",
  initialState: OrderInitialState,
  reducers: {
    createOrderRequest: (state) => {
      state.loading = true;
      state.order_details = undefined;
    },

    createOrderSuccess: (state, action: PayloadAction<OrderState>) => {
      state.loading = false;
      state.success = true;
      state.order_details = action.payload.order_details;
    },

    createOrderFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
  },
});

const orderIncompleteSlice = createSlice({
  name: "order",
  initialState: PickupOrderInitialState,
  reducers: {
    createIncompleteOrderRequest: (state) => {
      state.loading = true;
      state.pickup_order = undefined;
    },

    createIncompleteOrderSuccess: (
      state,
      action: PayloadAction<PickupOrderState>
    ) => {
      state.error = null;
      state.isOrderIncomple = false;
      state.loading = false;
      state.success = true;
      state.pickup_order = action.payload.pickup_order;
    },

    createIncompleteOrderFailure: (state, action: PayloadAction<string>) => {
      if (action.payload === "Please contact +919310353308 for Bulk Orders Thank You") {
        state.isOrderIncomple = true;
      }
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },

    createIncompleteOrderReset: (state) => {
      state.isOrderIncomple = false;
      state.loading = false;
      state.success = false;
      state.error = null;
      state.pickup_order = undefined;
    },
  },
});

const updateOrderSlice = createSlice({
  name: "updateOrder",
  initialState: UpdatePickupOrderInitilState,
  reducers: {
    updateOrderRequest: (state) => {
      state.loading = true;
      state.update_pickup_order = undefined;
      state.user_info = undefined;
    },

    updateOrderSuccess: (
      state,
      action: PayloadAction<UpdatePickupOrderState>
    ) => {
      state.loading = false;
      state.success = true;
      state.update_pickup_order = action.payload.update_pickup_order;
      state.user_info = action.payload.user_info
    },

    updateOrderFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    updateOrderReset: (state) => {
      state.error = null;
      state.loading = false;
      state.update_pickup_order = undefined;
      state.success = false;
    },
  },
});

const userOrderListSlice = createSlice({
  name: "userOrderList",
  initialState: UserOrderInitialState,
  reducers: {
    userOrderListRequest: (state) => {
      state.loading = true;
      state.user_orders = [];
    },

    userOrderListSuccess: (state, action: PayloadAction<UserOrderState>) => {
      state.loading = false;
      state.success = true;
      state.user_orders = action.payload.user_orders;
    },

    userOrderListFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    userOrderListReset: (state) => {
      state.error = null;
      state.loading = false;
      state.user_orders = [];
      state.success = false;
    },
  },
});

const orderDetailsSlice = createSlice({
  name: "userOrderDetails",
  initialState: OrderDetailsInitialState,
  reducers: {
    orderDetailsRequest: (state) => {
      state.loading = true;
      state.order_details = undefined;
    },

    orderDetailsSuccess: (state, action: PayloadAction<OrderDetailsState>) => {
      state.loading = false;
      state.success = true;
      state.order_details = action.payload.order_details;
    },

    orderDetailsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    orderDetailsReset: (state) => {
      state.error = null;
      state.loading = false;
      state.order_details = undefined;
      state.success = false;
    },
  },
});

const orderInquirySlice = createSlice({
  name: "orderInquiry",
  initialState: OrderInquiryInitialState,
  reducers: {
    orderInquiryRequest: (state) => {
      state.loading = true;
      state.results = undefined;
    },

    orderInquirySuccess: (state, action: PayloadAction<OrderInquiryState>) => {
      state.loading = false;
      state.success = true;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results;
    },

    orderInquiryFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    orderInquiryReset: (state) => {
      state.error = null;
      state.loading = false;
      state.results = undefined;
      state.success = false;
    },
  },
});

const deviceReportSlice = createSlice({
  name: "deviceReport",
  initialState: DeviceReportInitialState,
  reducers: {
    deviceReportRequest: (state) => {
      state.loading = true;
      state.device_report = undefined;
    },

    deviceReportSuccess: (state, action: PayloadAction<DeviceReportState>) => {
      state.loading = false;
      state.success = true;
      state.device_report = action.payload.device_report;
    },

    deviceReportFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    deviceReportReset: (state) => {
      state.error = null;
      state.loading = false;
      state.device_report = undefined;
      state.success = false;
    },
  },
});

const orderCancelSlice = createSlice({
  name: "orderCancel",
  initialState: CancelOrderInitialState,
  reducers: {
    orderCancelRequest: (state) => {
      state.loading = true;
      state.details = null;
    },

    orderCancelSuccess: (state, action: PayloadAction<CancelOrderState>) => {
      state.loading = false;
      state.success = true;
      state.details = action.payload.details;
    },

    orderCancelFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },

    orderCancelReset: (state) => {
      state.error = null;
      state.loading = false;
      state.success = undefined;
    },
  },
});

const ordersCountPriceSlice = createSlice({
  name: "ordersCountPrice",
  initialState: OrdersInitialState,
  reducers: {
    ordersRequest: (state) => {
      state.loading = true;
      state.orders = undefined;
    },

    ordersSuccess: (state, action: PayloadAction<OrdersState>) => {
      state.loading = false;
      state.success = true;
      state.orders = action.payload.orders;
    },

    ordersFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
  },
});

export const {
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  // createOrderReset,
} = orderInitSlice.actions;

export const OrderInitReducer = orderInitSlice.reducer;

export const {
  createIncompleteOrderFailure,
  createIncompleteOrderRequest,
  createIncompleteOrderSuccess,
  createIncompleteOrderReset,
} = orderIncompleteSlice.actions;

export const CreateIncomplteOrderReducer = orderIncompleteSlice.reducer;

export const {
  updateOrderRequest,
  updateOrderFailure,
  updateOrderSuccess,
  updateOrderReset,
} = updateOrderSlice.actions;

export const UpdateOrderReducer = updateOrderSlice.reducer;

export const {
  userOrderListFailure,
  userOrderListRequest,
  userOrderListReset,
  userOrderListSuccess,
} = userOrderListSlice.actions;

export const userOrderListReducer = userOrderListSlice.reducer;

export const {
  orderDetailsFailure,
  orderDetailsRequest,
  orderDetailsReset,
  orderDetailsSuccess,
} = orderDetailsSlice.actions;
export const orderDetailsReducer = orderDetailsSlice.reducer;

export const {
  deviceReportFailure,
  deviceReportRequest,
  deviceReportReset,
  deviceReportSuccess,
} = deviceReportSlice.actions;

export const deviceReportReducer = deviceReportSlice.reducer;

export const {
  orderInquiryFailure,
  orderInquiryRequest,
  orderInquiryReset,
  orderInquirySuccess,
} = orderInquirySlice.actions;

export const orderInquiryReducer = orderInquirySlice.reducer;

export const { orderCancelFailure, orderCancelRequest, orderCancelSuccess, orderCancelReset } =
  orderCancelSlice.actions;
export const OrderCancelReducer = orderCancelSlice.reducer;

export const { ordersFailure, ordersRequest, ordersSuccess } =
  ordersCountPriceSlice.actions;
export const ordersCountPriceReducer = ordersCountPriceSlice.reducer
