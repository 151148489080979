import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "../state/authState";


export interface UserInfoState {
  user_info:  UserInfo | null,
}
export const UserInfoInitialState: UserInfoState = {
  user_info: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState: UserInfoInitialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<UserInfoState>) => {
      const payload = action.payload;
      state.user_info = payload.user_info;
    },
    logOut: (state) => {
      state.user_info = null
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;

// export const selectCurrentUser = (state: any) => state.auth.user;
// export const selectCurrentToken = (state: any) => state.auth.token;

// const authSlice = createSlice({
//     name: 'auth',
//     initialState: { user: null, token: null },
//     reducers: {
//         setCredentials: (state, action) => {
//             const { user, accesToken } = action.payload
//             state.user = user;
//             state.token = accesToken;
//         },
//         logOut: (state) => {
//             state.user = null;
//             state.token = null;
//         }
//     },
// })

// export const { setCredentials, logOut } = authSlice.actions
// // // export const authReducer =  authSlice.reducer
// export default authSlice.reducer

// export const selectCurrentUser = (state: any) => state.auth.user
// export const selectCurrentToken = (state: any) => state.auth.token
