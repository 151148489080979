import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type backendState = {
  detail: string | null;
  error: string | null;
  loading: boolean;
  success?: boolean;
};

const backendInitialState: backendState = {
  detail: null,
  error: null,
  loading: false,
};

const bePartnerSlice = createSlice({
  name: "bePartner",
  initialState: backendInitialState,
  reducers: {
    bePartnerRequest: (state) => {
      state.loading = true;
      state.detail = null;
    },
    bePartnerSuccess: (state, action: PayloadAction<backendState>) => {
      state.loading = false;
      state.success = true;
      state.detail = action.payload.detail;
    },
    bePartnerFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    bePartnerReset: (state) => {
      state.loading = false;
      state.success = false;
      state.detail = null;
    },
  },
});

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState: backendInitialState,
  reducers: {
    contactUsRequest: (state) => {
      state.loading = true;
      state.detail = null;
    },
    contactUsSuccess: (state, action: PayloadAction<backendState>) => {
      state.loading = false;
      state.success = true;
      state.detail = action.payload.detail;
    },
    contactUsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    contactUsReset: (state) => {
      state.loading = false;
      state.success = false;
      state.detail = null;
    },
  },
});

export const {
  bePartnerFailure,
  bePartnerRequest,
  bePartnerReset,
  bePartnerSuccess,
} = bePartnerSlice.actions;

export const bePartnerReducer = bePartnerSlice.reducer;

export const {
  contactUsFailure,
  contactUsRequest,
  contactUsReset,
  contactUsSuccess,
} = contactUsSlice.actions;
export const contactUsReducer = contactUsSlice.reducer;
