import React, { useEffect, MouseEvent } from "react";

import { Spinner, Input } from "reactstrap";
import "./loginmodal.scss";
import { ChangeEvent, FormEvent } from "react";


interface LoginPasswordProps {
    switchToAuth: (choice: "login" | "signup" | "loginWithOtp") => void;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void,
    loginFormHandler: (e: FormEvent<HTMLFormElement>) => void,
    loginForm: {
        phone_number: string,
        password: string,
        otp: string,
    }
    UserLoginProps: {
        loginError: string | null,
        isLoginLoading: boolean
    }
}


const LoginWithPassWordModal: React.FC<LoginPasswordProps> = ({ switchToAuth, handleInputChange, loginFormHandler, loginForm,  UserLoginProps,}) => {

    const { loginError, isLoginLoading} = UserLoginProps;

    return (
        <>
            <div className="overlay-container none-mob">
                <div className="overlay">
                    <div className="overlay-panel overlay-right">
                        <img
                            className="img-log"
                            src="https://buybackart-s3-prod-bucket.s3.ap-south-1.amazonaws.com/static/userLogin/login.png"
                            alt="None"
                        />

                        {/* <p>Enter your personal details and start journey with us</p> */}
                        <button
                            className="ghost bttn-log"
                            id="signUp"
                            onClick={() => switchToAuth("signup")}
                        >
                            Sign Up
                        </button>
                        <p> or</p>
                        <button
                            className="ghost bttn-log mt-1"
                            onClick={() => switchToAuth("loginWithOtp")}
                        >
                            Login with OTP
                        </button>
                    </div>
                </div>
            </div>
            <div className="form-container sign-in-container">
                <form onSubmit={loginFormHandler} className="faram">
                    <h3 className="heading-log">Sign in</h3>
                    <div className="social-container">
                        <a href="#" className="social ahref">
                            <i className="fab fa-facebook-f" />
                        </a>
                        <a href="#" className="social ahref">
                            <i className="fab fa-google-plus-g" />
                        </a>
                    </div>

                    <Input
                        required
                        type="text"
                        placeholder="Phone"
                        className="inp"
                        name="phone_number"
                        value={loginForm.phone_number}
                        onChange={handleInputChange}
                    />

                    <Input
                        required
                        type="password"
                        placeholder="Password"
                        name="password"
                        className="inp"
                        value={loginForm.password}
                        onChange={handleInputChange}
                    />
                    {loginError && <div>{loginError}</div>}

                    <button
                        className={`bttn mb-1 mt-2 ${isLoginLoading ? "dimmed-button" : ""
                            }`}
                    >
                        {isLoginLoading && (
                            <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                                className=""
                            />
                        )}
                        <span className="mx-3">Sign In</span>
                    </button>
                    <h2>
                        <span className="none-bs">--OR--</span>
                    </h2>
                    <button
                        onClick={() => switchToAuth("loginWithOtp")}
                        className="bttn none-bs"
                    >
                        Login With OTP
                    </button>
                </form>
            </div>
        </>
    );
};

export default LoginWithPassWordModal;
