export type Variant = {
    id: number,
    variant: string,
    product: string,
    market_retail_price: number,
}

export type Attachedaccessory = {
    id: number,
    parent: string,
    image: string,
    product: string,
    weightage: number
}

export type ProductDetail = {
    id: number,
    name: string,
    product_category: string,
    product_series: string,
    image: string,
    variants: Variant[],
    attachedaccessory: Attachedaccessory[],
}

export type SearchProduct = {
    id: number,
    name: string,
    slug: string,
    image: string,
    category: string,
    brand: string,
}

export type ProductDetailState = {
    results: ProductDetail | undefined,
    success?: boolean,
    error: string | null,
    loading: boolean,
}

export type ExcitingOfferState = {
    results: any[],
    success?: boolean,
    error: string | null,
    loading: boolean,
}

export type SearchProductState = {
    results: SearchProduct[] | undefined,
    success?: boolean,
    error: string | null,
    loading: boolean,
    next?: string | null,
    previous?: string | null,
    count?: number | null
}

export type ProductVariantState = {
    results: Variant | undefined,
    success?: boolean,
    error: string | null,
    loading: boolean,
}

export const ProductDetailInitialState: ProductDetailState = {
    results: undefined,
    error: null,
    loading: false,
}

export const ExcitingOfferInitialState: ExcitingOfferState = {
    results: [],
    error: null,
    loading: false,
}

export const SearchProductInitialState: SearchProductState = {
    results: undefined,
    error: null,
    loading: false,
}

export const ProductVariantInitialState: ProductVariantState = {
    results: undefined,
    error: null,
    loading: false,
}