import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, CategoryListState } from "../state/categoryState";


const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categoryListRequest: (state) => {
      state.loading = true;
      state.categories = [];
    },
    categoryListSuccess: (state, action: PayloadAction<CategoryListState>) => {
      state.loading = false;
      state.success = true;
      state.categories = action.payload.categories;
      state.page = action.payload.page;
      state.pages = action.payload.pages;
    },
    categoryListFail: (state, action: PayloadAction<string>) => {
      state.success = false;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { categoryListRequest, categoryListSuccess, categoryListFail } =
  categorySlice.actions;
export const categoryListReducer =  categorySlice.reducer;
