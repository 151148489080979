import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ListWarrantyInitialState,
  ListWarrantyState,
} from "../state/warrantyState";

const warrantListSlice = createSlice({
  name: "warrantyList",
  initialState: ListWarrantyInitialState,
  reducers: {
    warrantyListRequest: (state) => {
      state.loading = true;
      state.warranty = undefined;
    },
    warrantyListSuccess: (state, action: PayloadAction<ListWarrantyState>) => {
      state.loading = false;
      state.success = true;
      state.warranty = action.payload.warranty;
    },
    warrantyListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { warrantyListRequest, warrantyListSuccess, warrantyListFailure } =
  warrantListSlice.actions;

export const WarrantyListReducer = warrantListSlice.reducer;
