
export type UserAddress = {
  id: number,
  name: string,
  street: string,
  landmark: string,
  city: string,
  state: string,
}

export type DailyUsers = {
  happy_user_count: number,
  daily_users: number
}

export type UserInfo = {
  access: string;
  refresh: string;
  id: number;
  name: string;
  phone_number: string;
  profile_image: string;
  email: string;
  city: string;
  user_address: UserAddress[] | [];
  postalCode: string;
  country: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  isAdmin: boolean;
};

export type UserInfoState = {
  user_info: UserInfo | undefined,
  loading: boolean,
  success?: boolean,
  error: string | null
}

export type DailyUsersState = {
  results: DailyUsers | undefined,
  loading: boolean,
  success?: boolean,
  error: string | null
}

export type DeleteUserState = {
  detail: string | null,
  loading: boolean,
  success?: boolean,
  error: string | null
}

export type UserAddressInfoState = {
  user_address: UserAddress[] | [],
  loading: boolean,
  success?: boolean,
  error: string | null
}

export const UserInfoInitialState: UserInfoState = {
  user_info: undefined,
  loading: false,
  error: null
};

export const DailyUsersInitialState: DailyUsersState = {
  results: undefined,
  loading: false,
  error: null
}

export const DeleteUserInitialState: DeleteUserState = {
  detail: null,
  loading: false,
  error: null
}

export const UserAddressInitialState: UserAddressInfoState = {
  user_address: [],
  loading: false,
  error: null
}

export type OtpState = {
  detail: string | null,
  otpSuccess?: boolean,
  loading: boolean,
  success?: boolean,
  error: string | null,
}

export const OtpInitialState: OtpState = {
  detail: null,
  loading: false,
  error: null,
}

export type CreateUserOtpState = {
  detail: string | null,
  loading: boolean,
  success?: boolean,
  error: string | null,
}

export const CreateUserOtpInitialState: CreateUserOtpState = {
  detail: null,
  loading: false,
  error: null,
}