import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../app/store'
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux"

export const useAppDispatch = () => {
  return useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
};

// export const useAppDispatch = useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector