export type Product = {
  id: number;
  name: string;
  image: string;
  slug: string;
};

export type BrandSeriesProduct = {
  id: number;
  name: string;
  slug: string;
  image: string;
}

export type MostSellingBrand = {
  id: number;
  title: string;
  image: string;
  slug: string;
  category: string;
}

export type MostSellingBrandProductList = {
  prod_id: string;
  product_name: string;
  prod_image: string,
  brand_image: string;
  order_count: number;
  brand_slug: string;
  product_slug: string;
}

export type brandListState = {
  id?: number | null
  title?: string | null;
  brands: Product[];
  success?: boolean;
  page: number | null;
  pages: number | null;
  error: string | null;
  loading: boolean;
};

export type brandSeriesListState = {
  id?: number | null
  series_brand: Product[];
  success?: boolean;
  error: string | null;
  loading: boolean;
};

export type brandProductListState = {
  results: Product[];
  success?: boolean;
  error: string | null;
  loading: boolean;
  count: number | null;
  next: string | null;
  previous: string | null;

}

export type brandProductSeriesListState = {
  id?: number | null
  product_series: BrandSeriesProduct[];
  success?: boolean;
  error: string | null;
  loading: boolean;
  page: number | null;
  pages: number | null;
}

export type MostSellingBrandState = {
  brand_list: MostSellingBrand[] | undefined,
  success?: boolean,
  loading: boolean;
  error: string | null;
}

export type MostSellingBrandProductListState = {
  most_selling_products: MostSellingBrandProductList[] | undefined,
  success?: boolean,
  loading: boolean;
  error: string | null;
}

export const brandInitialState: brandListState = {
  brands: [],
  page: null,
  pages: null,
  error: null,
  loading: false,
};

export const brandSeriesInitialState: brandSeriesListState = {
  series_brand: [],
  error: null,
  loading: false,
};

export const brandProductSeriesListInitialState: brandProductSeriesListState = {
  product_series: [],
  page: null,
  pages: null,
  error: null,
  loading: false,
}

export const brandProductListInitialState: brandProductListState = {
  results: [],
  next: null,
  previous: null,
  count: null,
  error: null,
  loading: false
}

export const MostSellingBrandInitialState: MostSellingBrandState = {
  brand_list: undefined,
  error: null,
  loading: false,
}

export const MostSellingBrandProductListInitialState: MostSellingBrandProductListState = {
  most_selling_products: undefined,
  error: null,
  loading: false,
}
