import "./thankyou.scss";
import React, { Fragment, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import AppDownload from "../../components/AppDownload/AppDownload";

const ThankYou: React.FC = ({ }) => {
  window.scrollTo(0, 0);

  const postRequestStatus = localStorage.getItem("postRequestMade")
  const navigate = useNavigate();

  useEffect(() => {
    if (!postRequestStatus) {
      navigate(`/`);
    }
  }, [navigate])

  return (
    <>
      {postRequestStatus ? (

        <div className="thank-you-page-container">
          <Container className="container-body" >
            <Row className="justify-content-center">
              <Col md={8} className="text-center">
                <h4 className="mb-4">
                  <strong>Your Order Has Been Placed Successfully</strong>
                </h4>
                <p>Thank you for choosing BuyBacKart!</p>
                <p className="mb-4">
                  Someone from our team will reach out to you shortly. Please
                  check your email for the order details.
                </p>
                <p className="mb-4">
                  Please keep a photocopy of your ID proof ready when our executive
                  picks up your device.
                </p>
                <Link to="/profile" className="d-block mb-4">
                  <Button variant="primary">
                    Check Your Order Status / History
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
       
        </div>

      ):
      <div>
        <h1>You are not authorized to view this page</h1>
      </div>
      }
         <div className="mb-5 mt-0">
          <AppDownload />
        </div>
    </>
  );
};

export default ThankYou;
