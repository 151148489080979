import Cookies from "js-cookie";

export const fetchUserCity = (setLocationUser: any) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            
            if (data.address) {
              let city = null;
              if (data.address.city) {
                city = data.address.city;
              } else if (data.address.state_district) {
                city = data.address.state_district
                  .replace("District", "")
                  .trim();
              }

              if (city) {
                const dataObject = {
                  city: city,
                };
                
                setLocationUser(dataObject)
                Cookies.set("userLocation", JSON.stringify(dataObject), { expires: 1 });
                // localStorage.setItem(
                //   "userLocation",
                //   JSON.stringify(dataObject)
                // );
              } else {
                console.error("City not found in address.");
              }
            } else {
              console.error("Geocoding API request failed.", data);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      },
      (error) => {
        console.error(error.message);
      }
    );
  } else {
    console.error("Geolocation is not supported in your browser.");
  }
};
