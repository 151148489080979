import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TestimonialListState, Testimonial, TestimonialListInitialState } from "../state/testimonialState";



const testimonialSlice = createSlice({
    name: "testimonial",
    initialState: TestimonialListInitialState,
    reducers: {
        testimonialRequest: (state) => {
            state.loading = true;
            state.testimonials = undefined;
        },
        testimonialSuccess: (
            state,
            action: PayloadAction<TestimonialListState>
        ) => {
            state.loading = false;
            state.success = true;
            state.testimonials = action.payload.testimonials;
        },
        testimonialFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});

export const { testimonialRequest, testimonialSuccess, testimonialFailure } =
    testimonialSlice.actions;
export const TestimonialReducer = testimonialSlice.reducer;