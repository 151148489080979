export type Ram = {
  id: number;
  ram: string;
  weightge: number;
  add: boolean;
};

export type GraphicCard = {
  id: number;
  graphic_card: string;
  weightge: number;
  add: boolean;
};

export type ScreenSize = {
  id: number;
  screen: string;
  weightge: number;
  add: boolean;
};

export type Processor = {
  id: number;
  processor: string;
  weightge: number;
  add: boolean;
};

export type ModelYear = {
  id: number;
  model: number;
  weightge: number;
  add: boolean;
};

export type Hardisk = {
  id: number;
  hard_disk: string;
  weightge: number;
  add: boolean;
};

export type RamState = {
  results: Ram[] | [];
  loading: boolean;
  error: string | null;
  success?: boolean;
};

export type GraphicCardState = {
  results: GraphicCard[] | [];
  loading: boolean;
  error: string | null;
  success?: boolean;
};

export type ScreenSizeState = {
  results: ScreenSize[] | [];
  loading: boolean;
  error: string | null;
  success?: boolean;
};

export type ProcessorState = {
  results: Processor[] | [];
  loading: boolean;
  error: string | null;
  success?: boolean;
};

export type ModelYearState = {
  results: ModelYear[] | [];
  loading: boolean;
  error: string | null;
  success?: boolean;
};

export type HardiskState = {
  results: Hardisk[] | [];
  loading: boolean;
  error: string | null;
  success?: boolean;
};

export const RamInitialState: RamState = {
  results: [],
  loading: false,
  error: null,
};

export const HardiskInitialState: HardiskState = {
  results: [],
  loading: false,
  error: null,
};

export const ProcessorInitialState: ProcessorState = {
  results: [],
  loading: false,
  error: null,
};

export const GraphicCardInitialState: GraphicCardState = {
  results: [],
  loading: false,
  error: null,
};

export const ModelYearInitialState: ModelYearState = {
  results: [],
  loading: false,
  error: null,
};

export const ScreenSizeInitialState: ScreenSizeState = {
  results: [],
  loading: false,
  error: null,
};
