
export type Variant = {
    id: number,
    variant: string,
    product: string,
    market_retail_price: number,
}

export type Parent = {
    id: number,
    name: string,
    slug: string,
    product_brand_slug: string,
    product_category_slug: string,
    image: string,
    variants: Variant[] | undefined
}

export type Result = {
    id: number,
    parent: Parent | undefined
}

export type ExcitingOfferListState = {
    results: Result[] | undefined,
    success?: boolean,
    loading: boolean,
    error: string | null
}

export const ExcitingOfferListInitialState: ExcitingOfferListState = {
    results: undefined,
    loading: false,
    error: null
}