
export type City = {
    id: number,
    state: string,
    name: string,
}

export type StateWithCity = {
    id: number,
    name: string,
    cities: City[],
}

export type CityList = {
    id: number,
    number: string,
    state: string,
    name: string,
    image: string,
}

export type StateWithCityState = {
    results: StateWithCity[] | [],
    loading: boolean,
    success?: boolean,
    error: string | null
}

export type CityListState = {
    results: CityList[] | undefined,
    next?: string | null,
    previous?: string | null,
    count?: number | null
    loading: boolean,
    success?: boolean,
    error: string | null
}

export const StateWithCityInitialState: StateWithCityState = {
    results: [],
    loading: false,
    error: null
}

export const CityListInitialState: CityListState = {
    results: undefined,
    loading: false,
    error: null
}